// third-party
import { FormattedMessage } from 'react-intl';

// assets
// import { IconAlignBoxBottomCenter } from '@tabler/icons';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
const icons = {
  AssessmentOutlinedIcon,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const apiBusinessPartner = {
  id: 'api-business-partner',
  type: 'group',
  children: [
    {
      id: 'api-business-partner',
      title: <FormattedMessage id="api-business-partner" />,
      type: 'item',
      url: '/api-business-partner',
      icon: icons.AssessmentOutlinedIcon,
      breadcrumbs: false,
    },
  ],
};

export default apiBusinessPartner;
