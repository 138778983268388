// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconWallet } from '@tabler/icons';
const icons = {
  IconWallet,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const wallet = {
  id: 'wallet',
  type: 'group',
  children: [
    {
      id: 'wallet',
      title: <FormattedMessage id="wallet" />,
      type: 'item',
      url: '/wallet',
      icon: icons.IconWallet,
      breadcrumbs: false,
    },
  ],
};

export default wallet;
