import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/styles";
import Header from "layout/Common/Header";
import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";

//
const Main = styled("main")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: "100%",
  minHeight: "calc(100vh - 88px)",
  flexGrow: 1,
  padding: "20px",
  marginTop: "88px",
}));

const HeaderLayout = () => {
  const theme = useTheme();

  const header = useMemo(
    () => (
      <Toolbar>
        <Header />
      </Toolbar>
    ),
    []
  );

  return (
    <>
      <Box>
        <CssBaseline />
        {/* header */}
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.default,
            boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.03)",
          }}
        >
          {header}
        </AppBar>

        <Main theme={theme}>
          <Container>
            <Outlet />
          </Container>
        </Main>
      </Box>
    </>
  );
};

export default HeaderLayout;
