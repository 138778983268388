import { lazy } from "react";

// project imports
import GuestGuard from "utils/route-guard/GuestGuard";
import MinimalLayout from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import Loadable from "ui-component/Loadable";

// login routing
const AuthLogin = Loadable(
  lazy(() => import("views/authentication/auth/Login"))
);
const AuthRegister = Loadable(
  lazy(() => import("views/authentication/auth/Register"))
);
const RegistrationSuccessful = Loadable(
  lazy(() => import("views/authentication/auth/Register-Success"))
);
const RegistraionVerify = Loadable(
  lazy(() => import("views/authentication/auth/Register-Verification"))
);
const AuthForgotPassword = Loadable(
  lazy(() => import("views/authentication/auth/ForgotPassword"))
);
const AuthResetPassword = Loadable(
  lazy(() => import("views/authentication/auth/ResetPassword"))
);
const VerifyUser = Loadable(
  lazy(() => import("views/authentication/auth/VerifyUser"))
);
const ConfomationVerification= Loadable(
  lazy(() => import("views/authentication/auth/ConformationEmail"))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: '/',
      element: <AuthLogin />,
    },
    {
      path: '/login',
      element: <AuthLogin />,
    },
    // {
    //     path: '/dashboard',
    //     element: <DashboardDefault />
    // },
    {
      path: '/register',
      element: <AuthRegister />,
    },
    {
      path: '/registration-success',
      element: <RegistrationSuccessful />,
    },
    {
      path: '/registration-verification',
      element: <RegistraionVerify />,
    },
    {
      path: '/verify-user',
      element: <VerifyUser />,
    },
    {
      path: '/forgot',
      element: <AuthForgotPassword />,
    },
    {
      path: '/resetPassword',
      element: <AuthResetPassword />,
    },
    {
      path: '/activateBusinessPartnerUsers/:token',
      element: <ConfomationVerification />,
    },
  ],
};

export default LoginRoutes;
