import apiBusinessPartner from './apibusinesspartner';
import reports from './reports';
import wallet from './wallet';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [reports, apiBusinessPartner, wallet],
};

export default menuItems;
