// material-ui
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";

// project imports
import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";
import LogoSection from "../LogoSection";

// assets
import { IconLock, IconLogout, IconSettings } from "@tabler/icons";
import useConfig from "hooks/useConfig";
import { useNavigate } from "react-router-dom";

import { ReactComponent as LeftArrowIcon } from "assets/icons/svg/double-left-arrow.svg";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme();
  const { borderRadius } = useConfig();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePassword = () => {
    navigate("/Change-Password");
    handleClose();
  };

  const handleLogout = () => {
    navigate("/login");
    handleClose();
  };

  const handleSettings = () => {
    navigate("/settings");
    handleClose();
  };

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => dispatch(openDrawer(!drawerOpen))}
        >
          <Box
            component="span"
            sx={{
              display: { xs: "none", md: "block", marginRight: "45px" },
              flexGrow: 1,
            }}
          >
            <LogoSection />
          </Box>
          <IconButton>
            <LeftArrowIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#FC5719",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          <Typography
            variant="subtitle1"
            sx={{ marginRight: "10px", color: "#FC5719" }}
          >
            My Account
          </Typography>
        </Box>
      </Box>

      {/* menu dropdown */}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSettings}>
          <IconSettings
            className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"
            style={{ marginRight: "8px" }}
          />
          Settings
        </MenuItem>
        <MenuItem onClick={handleChangePassword}>
          <IconLock
            className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"
            style={{ marginRight: "8px" }}
          />
          Change Password
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLogout();
          }}
        >
          <IconLogout
            className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"
            style={{ marginRight: "8px" }}
          />
          Log Out
        </MenuItem>
      </Menu>
    </>
  );
};

export default Header;
