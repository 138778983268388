import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import Loadable from 'ui-component/Loadable';
import HeaderRoutes from './HeaderRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([LoginRoutes, MainRoutes, HeaderRoutes]);
}
