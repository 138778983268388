import { lazy } from 'react';

import HeaderLayout from 'layout/HeaderLayout';

import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
const ChoosePlan = Loadable(lazy(() => import('views/choose-plan')));

// ==============================|| MAIN ROUTING ||============================== //

const HeaderRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <HeaderLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/choose-plan',
      element: <ChoosePlan />,
    },
  ],
};

export default HeaderRoutes;
